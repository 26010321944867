
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* header */
.header_company{
  color: snow;
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 5px;
  margin:15px;
}

.header_username{
  color: snow;
  font-weight: bolder;
  font-size: 20px;
  margin:15px;
}

/* template */
.template_title{
  font-size: 20px;
  padding: 10px;/*文字の上下 左右の余白*/
  padding-right: 30%;
  color: #727272;/*文字色*/
  background: #ebebeb;/*背景色*/
  border-left: solid 5px #9adc8d;/*左線*/
  border-bottom: solid 3px #d7d7d7;/*下線*/
  margin: 10px 0px;
}


.input_text_container{
  margin: 20px 0px;
}


.modal_float{
  margin: 5px 0px;
  display: flex;
}
.modal_spacer{
  margin-top: 30px;
}

.modalTextField{
  margin : 10px; 
}
.modalFormControl{
  margin: 0px 5px 5px 5px !important;
}
.modalSelectField{
  width: 100%;
  /* min-width: 50%; */
  min-width: 180px;
}
.modalSelectField_harf{
  width: 100%;
  min-width: 20%;
  /* min-width: 156px; */
}
/* Dialog start */
.dialogTextBox{
  margin :20px;
}
/* Dialog end */

/* common */
.link{
  color: blue;
}

/* kintai_table */

.shift_type{
  text-align: center;
  padding: 5% 5%;
  border-radius: 5px;
  background-color: rgb(238, 238, 238);
}

.approval_status{
  text-align: center;
  padding: 5% 0%;
  border-radius: 5px;
}
/* 差戻しColor */
.approval_status_color80{ 
  background-color:tomato;
  color: snow
}

/* request_kintai_form */
.acction_button{
  width: 100px;
  height:50px;
  margin: 7px !important;
  background-color: #8dbb5a !important;
  color: white !important;
  border-color: #9adc8d;
}

.acction_button_disabled{
  background-color: #c0c0c0 !important;
}

.add_shift_button{
  width: 20px;
  height:56px;
  margin: 7px !important;
  padding: 3px !important;
  /* background-color: rgb(231, 231, 231) !important; */
}

.add_shift_button_disable{
  background-color: rgb(158, 158, 158) !important;
  min-width: auto !important;
  margin-left:0px !important;
  font-size: 10px !important;
  width:30px;
}

.add_shift_button_enable{
  background-color: rgb(231, 231, 231) !important;
}

.kintai_form_note{
  width: 150px !important;
}

.coution{
  color: red;
  font-style:initial;
}

.kintai_form_info{
  width: 125px !important;
}

.RequestKintai_time{
  width: 175px !important;
}

.counterContent{
  width:100%;
  margin-left: 7px;
  margin-right: 25px;
  padding: 5px 15px;
  border: solid 1px;
  border-radius: 4px;
  border-color: rgb(199, 199, 199);
  
}

.errmsg{
  margin-bottom: 10px;
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: larger;
  min-height: 20px;
}
.tooltipspan{
  width: 100%;
}

/* kintai_header start*/

.request_kintai_header_date{
  text-align: center !important;
}

.diagonal{
  /* background-image: linear-gradient(
        to right bottom, transparent calc(50% - 0.5px), #eaeaea 50%, #9c9c9c calc(50% + 0.5px), #8dbb5a calc(50% + 1px)
    ); */
  background-image: linear-gradient(
        to right bottom, transparent calc(50% - 0.5px), #eaeaea 50%, mistyrose calc(50% + 0.5px), mistyrose calc(50% + 1px)
    );
  display: grid;
  width: max-content;
  min-width: 90px;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
}

.col-header {
  grid-column-start: 1;
  text-align: left;
  font-size: 1.1rem;
}
.row-header {
  grid-column-start: 2;
  text-align: right;
  font-size: 1.2rem;
}
.nagativeValue {
  color: red;
  font-weight: bolder;
  font-size: 1.3rem
}
/* kintai_header end*/

/* approval_kintai_header */
.serchBox{
  width: 130px !important;
}

.headerActionButton{
  width: 90px;
  margin: 0px 5px !important;
}
.headerSearchButton{
  width:290px;
  margin: 5px 5px !important;
  background-color: #d1e6ba !important;
}



/* approval_kintai_table */

.helper_clockin_time{
  color:#a1a1a1 !important;
  font-size: 0.8rem !important;
}

/* approval_user_list start */

.commit_error{
  color:red !important;
  font-weight: bold !important;
  /* font-size: 0.8rem !important; */
}
.commit_completed{
  color:blue !important;
  font-weight: bold !important;
  /* font-size: 0.8rem !important; */
}

/* approval_user_list end */
/* ---------------------------------------- */


.kintaiList_userName{
  font-weight: bold !important;
  font-size: 25px !important;
}

.alert{
  font-weight: bold !important;
  font-size: 120% !important;
  color: tomato !important;
}

.depmstTreeView{
  width: 100%;
}

.depmstTreeItem{
  height: 40px;
  width: 100%;
}

.MuiTreeItem-label{
  padding:8px;
}

.titlelogo{
  background-image: url("./img/titlelogo.png");
  background-repeat:no-repeat;
  background-size:contain;
  height: 200px;
  width: 300px;
  margin: 40% auto 20px auto;
}

.titleheader{
  height: 60px;
  background-color:darkseagreen;
}

.flex{
  display: flex;
}

.clear{
  clear:block;
}

.signinButton{
  background:darkseagreen !important;
  color:whitesmoke !important;
}

.copyright{
  margin-top: 20px  !important;
  color: #282c34  !important;
}

/* shift_mst */
.shiftMstPage_wrapper {
  display: flex;
}
.shiftMstPage_content {
  margin: 0px 15px;
}

.shiftmstFormControl{
  margin: 7px 7px 0px 7px !important;
}
.InputLabel-style{
  padding: 0px 14px !important;
}
.shiftmstFormSelect{
  min-width: 200px;
  color: #282c34;
}


/* public_holiday_mst start */
.cal_container{
  margin:0px 5px;
  border-radius: 4px;
}

.type_name_container{
  display: flex;
  background-color: #e2e2e2;
  padding: 5px;
  margin: 5px;
  border-radius: 8px;
}

.type_name{
  text-align: center;
  color: rgb(129, 129, 129);
  font-weight: bold;
  display: grid;
  place-items: center;
  width: 100%;
  height: 50px;
  padding-left:30px;
  padding-top:5px;
  /* border: solid 0.5px #bef1b7; */
}


/* public_holiday_mst  end */

.del{
  background-color: lightslategray !important;
}

.colorsample_item{
  float: left;
  height:20px;
  width:10px;
  margin-right:10px;
}
.shift_list{
  max-height: '80vh';
  overflow: auto;    
}
/* Clockin */





.clockin_toolbar{
  background-image: url("./img/headerlogo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  min-height: 64px;
}

.clockin_main_container{
  height: 85vh;
  font-family: 'M PLUS 1p', sans-serif !important;
  font-weight: 800;
  color: #666a79 !important;
}

.clockin_container{
  width: 100%;
  height: 100%;
  margin: auto;
  top:  0;                     /* 位置指定 */
  bottom:  0;                  /* 位置指定 */
  left:  0;                    /* 位置指定 */
  right:  0;                   /* 位置指定 */
}

.clockin_contents{
  width: 60%;
  height: 15%;
  margin: auto;
  display: table;
}

.time_contents{
  display: table-cell;
  vertical-align: middle;
  padding-top: 40px;
  height: 350px;
}

.switch_contents{
  height: 5%;
}

.switch{
  text-align: right;
  display: table-cell;
  vertical-align: bottom;
}

.clockin_select{
  width: 48%;
  margin:3px !important;
  font-family: 'M PLUS 1p', sans-serif !important;
}
.clockin_select .MuiSelect-select{
  font-size: 30px;
  line-height:30px;
}
.clockin_select label{
  font-size: 20px;
  line-height:40px;
}

.select_tworow{
  width: 100%;
}

.clockin_menuitem{
  font-size: 30px !important;
  font-family: 'M PLUS 1p', sans-serif !important;
}

.clockin_time{
  width: 100%;
  font-size: 90px;
  margin: auto;
  text-align: center;
}
.result_message{
  width: 100%;
  height: 50px;
  font-size: 30px;
  margin: auto;
  text-align: center;
}

.Btton_container{
 margin: auto; 
}

.clockin_type_button{
  width: 30%;
  height: 150px;
  margin: 1.6% !important;
  font-size: 40px !important;
  font-family: 'M PLUS 1p', sans-serif !important;
  font-weight: 800 !important;
  color: #666a79 !important;
}

.enter_botton{
  border: 5px dashed rgb(192, 236, 155) !important;
  background-color: rgb(243, 250, 238) !important;
}

.clockin_type_button:hover{
  color: snow !important;
  font-size: 50px !important;
  transition: 0.2s ;
}

.enter_botton:hover{
  /* border: 10px dashed rgb(245, 248, 241) !important; */
  border-width: 10px !important;
  background-color: rgb(189, 221, 162) !important;
}

.outer_button{
  border: 5px dashed rgb(163, 193, 238) !important;
  background-color: rgb(247, 250, 255) !important;
}
.outer_button:hover{
  /* border: 10px dashed rgb(245, 248, 241) !important; */
  border-width: 10px !important;
  background-color: rgb(178, 198, 228) !important;
}
.selected{
  border-style: solid !important;
  border-color: rgb(243, 132, 67) !important;
}
.not_selected{
  /* border: 5px dashed rgb(193, 193, 193) !important;
  background-color: rgb(242, 241, 241) !important; */
}

.nfc_connect_button{
  width: 100%;
  height: 150px;
  margin: 10px auto !important;
  font-size: 40px !important;
  font-family: 'M PLUS 1p', sans-serif !important;
  font-weight: 800 !important;
  color: #666a79 !important;
}
.nfc_loader {
  border: 8px solid #fafafa;
  border-radius: 50%;
  border-top: 12px solid #3498db;
  width: 75px;
  height: 75px;
  margin: 0px 30px !important; 
  animation: spin 1s linear infinite;
}

.small_loader{
  border: 5px solid #fafafa;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 40px;
  height: 40px;
}

/* end */
/* importShiftEdter start */
.rowReds{
  background-color: 'Gold';
  color: 'Red';
}
/* importShiftEdter end */

@keyframes spin{
  0%{
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 960px) {
  .clockin_contents{
    width: 95%;
  }
  .clockin_select .MuiSelect-select{
    font-size:30px;
    line-height:75px;
  }
  .clockin_select label{
    font-size: 25px;
    line-height:75px;
  }
  .clockin_time{
    width: 100%;
    font-size: 70px;
  }
  .result_message{
    width: 100%;
    height: 20px;
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .clockin_contents{
    width: 95%;
    height: 15%;
  }
  .clockin_select .MuiSelect-select{
    font-size: 20px;
    line-height:20px;
  }
  .clockin_select label{
    font-size: 20px;
    line-height:20px;
  }
  .clockin_time{
    width: 100%;
    font-size: 50px;
  }
  .result_message{
    width: 100%;
    height: 20px;
    font-size: 20px;
  }
  .clockin_type_button{
    width: 42%;
    height: 100px;
    font-size: 30px !important;
  }
  .clockin_type_button:hover{
    font-size: 30px !important;
  }
  .switch{
    font-size: 10px !important;
  }
}



/* sp_kintai */
.shint_info_contents{
  display: flex;
  margin:20px  5px ;
}

.shift_info{
  width: 50%;
  font-size: 25px;
  margin: auto;
}


.label{
  text-align: left;
}
.time{
  text-align: center;
}

.sp_harf{
  width: 45% !important;
}

.sp_button{
  width: 95%;
}